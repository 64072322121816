import Vue from 'vue'
import Vuex from 'vuex'

import createPersistedState from "vuex-persistedstate";
import staticData from '@api/staticData'

Vue.use(Vuex)

const store = new Vuex.Store({
    plugins: [createPersistedState()],
    state: {
        isLogged: false,
        user: null,

        listaEstados: [],
    },
    getters: {

    },
    mutations : {
        isLogged(state, payload){
            state.isLogged = payload
        },
        setUser(state, payload){
            state.user = payload
        },
        setListaEstados(state, payload){
            state.listaEstados = payload
        },
    },
    actions: {
        async loadStaticData({commit}){
            const listaStaticData = await staticData.getAll()
            commit('setListaEstados', listaStaticData.data.estados)
        }
    },
})

export default store
