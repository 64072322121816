import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/store'


Vue.use(VueRouter)

const routes = [{
        path: "/",
        meta: {
            requiresAuth: true
        },
        component: () => import('@templates/default/Index'),
        children: [{
                path: '',
                name: 'Home',
                meta: {
                    title: "Home"
                },
                component: () => import('@pages/Home/Home')
            },
            {
                path: 'administrativo/logs',
                name: 'AdministrativoLogs',
                meta: {
                    title: "Logs"
                },
                component: () => import('@pages/Administrativo/Logs')
            },
            {
                path: 'clientes',
                name: 'Cliente',
                meta: {
                    title: "Clientes"
                },
                component: () => import('@pages/Clientes/Cliente')
            },
            {
                path: 'clientes/create',
                name: 'ClienteCreate',
                meta: {
                    title: "Novo Cliente"
                },
                component: () => import('@pages/Clientes/ClienteCreate')
            },
            {
                path: 'clientes/edit/:id',
                props: true,
                name: 'ClienteEdit',
                meta: {
                    title: "Editar Cliente"
                },
                component: () => import('@pages/Clientes/ClienteEdit')
            },
            {
                path: 'clientes/show/:id',
                props: true,
                name: 'ClienteShow',
                meta: {
                    title: "visualizar Cliente"
                },
                component: () => import('@pages/Clientes/ClienteShow')
            },

            {
                path: 'operadores',
                name: 'Operador',
                meta: {
                    title: "Operadores"
                },
                component: () => import('@pages/Operadores/Operador')
            },
            {
                path: 'operadores/create',
                name: 'OperadorCreate',
                meta: {
                    title: "Novo Operador"
                },
                component: () => import('@pages/Operadores/OperadorCreate')
            },
            {
                path: 'operadores/edit/:id',
                props: true,
                name: 'OperadorEdit',
                meta: {
                    title: "Editar Operador"
                },
                component: () => import('@pages/Operadores/OperadorEdit')
            },

            {
                path: 'administradores',
                name: 'Administrador',
                meta: {
                    title: "Administradores"
                },
                component: () => import('@pages/Administradores/Administrador')
            },
            {
                path: 'administradores/create',
                name: 'AdministradorCreate',
                meta: {
                    title: "Novo Administrador"
                },
                component: () => import('@pages/Administradores/AdministradorCreate')
            },
            {
                path: 'administradores/edit/:id',
                props: true,
                name: 'AdministradorEdit',
                meta: {
                    title: "Editar Administrador"
                },
                component: () => import('@pages/Administradores/AdministradorEdit')
            },

            {
                path: 'robos',
                name: 'Robo',
                meta: {
                    title: "Robôs"
                },
                component: () => import('@pages/Robos/Robo')
            },
            {
                path: 'robos/create',
                name: 'RoboCreate',
                meta: {
                    title: "Novo Robô"
                },
                component: () => import('@pages/Robos/RoboCreate')
            },
            {
                path: 'robos/edit/:id',
                props: true,
                name: 'RoboEdit',
                meta: {
                    title: "Editar Robô"
                },
                component: () => import('@pages/Robos/RoboEdit')
            },
        ]
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@pages/Auth/Login'),
        //meta: {guest: true},
    },
]


const router = new VueRouter({
    mode: "history",
    routes
})

function loggedIn() {
    return store.state.isLogged;
}


const DEFAULT_TITLE = 'e.Aí';
router.afterEach((to, from) => {
    Vue.nextTick(() => {
        document.title = 'e.Aí - ' + to.meta.title || DEFAULT_TITLE;
    });
})

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        // this route requires auth, check if logged in
        // if not, redirect to login page.
        if (!loggedIn()) {
            next({
                path: '/login',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    } else if (to.matched.some(record => record.meta.guest)) {
        if (loggedIn()) {
            next({
                path: '/',
                query: {
                    redirect: to.fullPath
                }
            })
        } else {
            next()
        }
    } else {
        next() // make sure to always call next()!
    }
})

export default router
